<template>
  <div class="main">
    <a-layout class="mlayout">
      <a-layout>
        <a-layout-content
          class="mobile indexLayoutContent"
          style="padding: 0px"
        >
          <div class="page mindex flex-col justify-start" style="height: auto">
            <div class="mswiper windowheight">
              <top-b
                :kind="``"
                :topbarhide="topbarhide"
                :tb="tb"
                :w750="w750"
                :topbarOpacity="topbarOpacity"
                :lag="lag"
              ></top-b>
              <div class="swiper sc">
                <div :class="`swiper-wrapper`">
                  <div
                    :class="`swiper-slide ssm0 sg00`"
                    style="background-color: rgba(41, 59, 26, 1)"
                  >
                    <div class="swiper lsc0">
                      <div class="swiper-wrapper">
                        <div class="swiper-slide">
                          <div class="group_2_0 flex-col mao20 windowheight">
                            <div class="g20 flex-col" :style="``">
                              <div class="g20in flex-col">
                                <div class="gbar flex-col justify-between">
                                  <div class="gtitles flex-col">
                                    <span data-v-3f1e1d78="" class="text_13">
                                      <img
                                        src="https://cdn.moheweb.com/vdb/lweb/imgs/title20.png"
                                        style="height: 64px"
                                      />
                                    </span>
                                  </div>
                                  <img
                                    style="
                                      width: 200px;
                                      height: 1px;
                                      margin: 39px 0 0 0px;
                                    "
                                    class="image_2"
                                    referrerpolicy="no-referrer"
                                    src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng77a881200aafe17d1ed232488c012d1b886eefc33dd61641a8ce8eb3885bf2f1"
                                  />
                                  <div class="gdes flex-col">
                                    <div
                                      class="textline"
                                      style="margin-top: 20px"
                                    >
                                      All&nbsp;Fields带你看世界-我们提供最舒适的户外设备，精选好物清单，还有各类实用专业指南，让你「闭眼」购物，更多好物等你来发现！
                                    </div>

                                    <div
                                      class="btns flex-row justify-start"
                                      style="margin-top: 40px"
                                    >
                                      <div
                                        class="lbtn flex-row justify-center"
                                        style="margin-right: 4rem"
                                        @click="goyouzan()"
                                      >
                                        <div
                                          class="lbtnin flex-row justify-between"
                                        >
                                          <span class="text-group_123"
                                            >了解更多</span
                                          >

                                          <img
                                            data-v-3f1e1d78=""
                                            referrerpolicy="no-referrer"
                                            src="https://cdn.moheweb.com/vdb/lweb/imgs/wj.png"
                                            class="image_186"
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="backimg"></div>
                  </div>

                  <div class="swiper-slide ssm0 sg21">
                    <div class="swiper lsc0">
                      <div class="swiper-wrapper">
                        <div
                          class="swiper-slide"
                          style="background-color: rgba(0, 0, 0, 0.05)"
                        >
                          <div class="group_2_1 flex-col mao21">
                            <div class="g21 flex-col" :style="``">
                              <img
                                class="image_20"
                                referrerpolicy="no-referrer"
                                src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng43e13759fd61200dd2aec8f3f42b50d1271142b403a2181742c322bf702eb876"
                                style="
                                  width: calc(100% - 160px);
                                  height: 1px;
                                  margin: 0 auto;
                                "
                              />
                              <div class="g21in flex-col">
                                <div
                                  class="gbar flex-col justify-between"
                                  style="margin-top: 100px"
                                >
                                  <div class="gtitles flex-col">
                                    <span
                                      class="text_13"
                                      style="color: rgba(50, 50, 50, 1)"
                                    >
                                      <img
                                        src="https://cdn.moheweb.com/vdb/lweb/imgs/title21.png"
                                        style="height: 64px"
                                      />
                                    </span>
                                  </div>
                                  <img
                                    style="
                                      width: 200px;
                                      height: 1px;
                                      margin: 39px 0 0 0px;
                                    "
                                    class="image_2"
                                    referrerpolicy="no-referrer"
                                    src="https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPnga315fb212ffff37cf080d4749d9991c062b5d762169560cdffdd9667c04f91cf"
                                  />
                                  <div class="gdes flex-col">
                                    <div
                                      class="textline"
                                      style="
                                        margin-top: 4px;
                                        color: rgba(50, 50, 50, 1);
                                      "
                                    >
                                      你也喜欢露营么？此栏目应该很适合你-这里精选各种好物推荐，<br />一起来看看啊～
                                    </div>
                                  </div>

                                  <router-link :to="`/haowulistm/1/${lag}`">
                                    <div
                                      class="lmore flex-row justify-center"
                                      style="
                                        position: relative;
                                        margin-top: 0px;
                                        margin-bottom: 0px;
                                        margin-top: 40px;
                                      "
                                    >
                                      <div
                                        class="lmorein flex-row justify-between"
                                      >
                                        <span class="text-group_123"
                                          >了解更多</span
                                        >
                                        <img
                                          referrerpolicy="no-referrer"
                                          src="https://cdn.moheweb.com/vdb/lweb/imgs/bj.png"
                                          class="image_186 bjt"
                                        />

                                        <img
                                          referrerpolicy="no-referrer"
                                          src="https://cdn.moheweb.com/vdb/lweb/imgs/wj.png"
                                          class="image_186 wjt"
                                          style="display: none"
                                        />
                                      </div>
                                    </div>
                                  </router-link>
                                </div>

                                <div class="g21list">
                                  <template v-for="(item, index) in clist21">
                                    <div :class="`g21div`" :key="index">
                                      <div
                                        class="g21item"
                                        :style="`background-color: #ffffff;`"
                                      >
                                        <div
                                          class="g21backimg"
                                          :style="`background-image: url(${
                                            item.imgurl.indexOf('https://') ==
                                            -1
                                              ? baseUrl + item.imgurl
                                              : item.imgurl
                                          });`"
                                        >
                                          <div class="g21backcolor"></div>
                                        </div>
                                        <div class="g21info">
                                          <div class="tinfo">
                                            <div class="tinfoleft">
                                              <span>ALL&nbsp;FIELDS</span>
                                              <span
                                                v-html="item.addtime"
                                              ></span>
                                            </div>
                                            <div class="tinforight">运动</div>
                                          </div>
                                          <div
                                            class="g21title"
                                            v-html="item.pname"
                                          ></div>
                                          <div
                                            class="g21des"
                                            v-html="item.des"
                                          ></div>

                                          <div class="g21btns">
                                            <div
                                              class="lmore flex-row justify-center"
                                              style="
                                                position: relative;
                                                margin-top: 0px;
                                                margin-bottom: 0px;
                                                right: 0px;
                                                bottom: 3.6rem;
                                              "
                                              @click="
                                                () => {
                                                  godetall(
                                                    item.url,
                                                    item.pname,
                                                    item.kind,
                                                    item.id
                                                  );
                                                }
                                              "
                                            >
                                              <div
                                                class="lmorein flex-row justify-between"
                                              >
                                                <span class="text-group_123"
                                                  >了解更多</span
                                                >
                                                <img
                                                  referrerpolicy="no-referrer"
                                                  src="https://cdn.moheweb.com/vdb/lweb/imgs/bj.png"
                                                  class="image_186 bjt"
                                                />

                                                <img
                                                  referrerpolicy="no-referrer"
                                                  src="https://cdn.moheweb.com/vdb/lweb/imgs/wj.png"
                                                  class="image_186 wjt"
                                                  style="display: none"
                                                />
                                              </div>
                                            </div>

                                            <div
                                              class="lbtn flex-row justify-center"
                                              :style="`
                                                position: relative;
                                                margin-top: 0px;
                                                margin-bottom: 0px;
                                                right: 0px;
                                                bottom: 3.6rem;
                                                background-color: rgba(247, 96, 31, .75) ;
                                              `"
                                              @click="
                                                () => {
                                                  godetall(
                                                    item.url,
                                                    item.pname,
                                                    item.kind,
                                                    item.id
                                                  );
                                                }
                                              "
                                            >
                                              <div
                                                class="lbtnin flex-row justify-between"
                                              >
                                                <span class="text-group_123"
                                                  >了解更多</span
                                                >

                                                <img
                                                  data-v-3f1e1d78=""
                                                  referrerpolicy="no-referrer"
                                                  src="https://cdn.moheweb.com/vdb/lweb/imgs/wj.png"
                                                  class="image_186"
                                                />
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <!-- </router-link> -->
                                      </div>
                                    </div>
                                  </template>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="backimg"></div>
                  </div>

                  <div class="swiper-slide">
                    <footer-b
                      :kind="`index`"
                      :g8top="g8top"
                      :g8left="g8left"
                      :lag="lag"
                    ></footer-b>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </a-layout-content>
      </a-layout>
    </a-layout>
  </div>
</template>

<script>
import {
  fetchClass,
  fetchCase,
  fetchFriend,
  fetchBase,
  fetchAwards,
} from "../api/index";
import FooterB from "../components/FooterB.vue";
import TopB from "../components/TopB.vue";
// const Base64 = require('js-base64').Base64
import Swiper, {
  FreeMode,
  Autoplay,
  Effect,
  Pagination,
  Mousewheel,
  Scrollbar,
} from "swiper";
Swiper.use([FreeMode, Autoplay, Effect, Pagination, Mousewheel, Scrollbar]);
import "swiper/swiper-bundle.css";
import global from "../common";
import $ from "jquery";
export default {
  name: "index",
  components: {
    FooterB,
    TopB,
  },
  props: {
    msg: String,
  },
  watch: {
    $route(to, from) {
      // to.params为最新的参数
      // from.params为之前的参数
      console.log(to.params);
      this.id = to.params["id"];

      // this.$el
      //   .querySelector(`.mao${this.id}`)
      //   .scrollIntoView({ block: "start", behavior: "smooth" });
    },
  },
  data() {
    return {
      clist00: [],
      clist21: [],
      g02list: [],

      g01list: [],

      pageHeight: 0,
      tbanimate: "down",
      winHeight: 0,
      topbarhide: 0,
      id: "0",
      lag: "",
      w750: 0,
      m_group_2_height: 0,
      basedata: [],
      frienddata: [],
      frienddata2: [],
      ppswiperindex: 0,
      nopplistshow: 0,
      baseUrl: global.baseUrl,
      thisclass1: 68,
      thisclass2: "",

      classlist: [],

      class2list: [],

      swiper: null,

      swiper0: null,

      swiper1: null,

      swiper2: null,

      swiper3: null,

      swiper03: null,

      selectIndex: 0,

      barclass: "closethis",
      pageWidth: 1920,
      topbarOpacity: 0,
      mkinds: [],
      ppindex: -1,
      pplist: [],
      bselectindex: -1,

      sectionindex: 0,
      sections: [],
      data: [],
      dataloading: 0,
      pageSize: 12,
      query: {
        class1: [],
        class2: [],
      },
      alive: true,
      adata: [],
      awardsindex: 0,

      opy1: 0,
      opy2: 0,
      opy3: 0,
      opy4: 0,
      opy5: 0,
      opy6: 0,
      opy7: 0,
      opy8: 0,

      group1h: 0,
      group2h: 0,
      group3h: 0,
      group4h: 0,
      group5h: 0,
      group6h: 0,
      group7h: 0,

      tb: true,
      masktop: 0,
      masksize: 0,
      g8top: 0,
      g8left: 0,
      initHeight: 0,
      ISwx: 0,
    };
  },

  activated() {
    let that = this;
    console.log("onActivated");
    let scrollvalue = global.getIndexscroll();
    if (scrollvalue > 0) {
      $(".main").scrollTop(scrollvalue);
    }

    if (scrollvalue > 400) {
      this.topbarOpacity = (scrollvalue - 400) / 200;
      if (this.topbarOpacity > 1) {
        this.topbarOpacity = 1;
      }
    } else {
      this.topbarOpacity = 0;
    }

    that.resizepage();

    setTimeout(() => {
      let classtext = global.getIndexDclass();
      // console.log("classtext:", classtext);
      if (classtext != "") {
        that.goDiv(classtext, "");
      }
      // let video0 = document.getElementById("video0");
      // video0.play();
    }, 1000);
    this.alive = true;
    this.handleScroll;
  },
  deactivated() {
    console.log("deactivated:");
    this.alive = false;
  },

  mounted() {
    let that = this;

    let id = this.$route.params.id;

    let lag = this.$route.params.lag;

    if (id) {
      this.id = id;
      // setTimeout(() => {
      //   this.$el
      //     .querySelector(`.mao${this.id}`)
      //     .scrollIntoView({ block: "start", behavior: "smooth" });
      // }, 500);
    }

    if (lag) {
      this.lag = lag;
    }

    console.log("this.lag:", this.lag);

    if (this.lag == "en") {
      this.sections = this.ensections;
    } else {
      this.sections = this.chsections;
    }
    global.setIndexDclass("");
    global.setIndexscroll(0);
    // that.handleScroll;
    that.initHeight = window.innerHeight;
    that.resizepage();
    // this.$el.addEventListener("scroll", that.handleScroll, true);
    // window.addEventListener("resize", that.handleResize, true);
    window.onresize = () => {
      that.handleResize();
    };

    setTimeout(() => {
      that.initHeight = window.innerHeight;
      that.resizepage();
    }, 500);

    setTimeout(() => {
      $(document).on("click", ".closesw", function () {
        $(".showppdiv").fadeTo(300, 0, () => {
          $(".showppdiv").css({ left: "-100%" });
        });
      });
      $(document).on("click", ".closesw3", function () {
        $(".showawardsdiv").fadeTo(300, 0, () => {
          $(".showawardsdiv").css({ left: "-100%" });
        });
      });
    }, 1000);

    this.fetch21();
    that.myswiper();

    // wx.config({
    //   debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
    //   appId: "wxa2c2b720fa045317", // 必填，公众号的唯一标识
    //   timestamp: this.timestamp, // 必填，生成签名的时间戳
    //   nonceStr: this.nonceStr, // 必填，生成签名的随机串
    //   signature: this.signautre, // 必填，签名
    //   jsApiList: [
    //     "onMenuShareTimeline",
    //     "onMenuShareAppMessage",
    //     "checkJsApi",
    //     "scanQRCode",
    //   ], // 必填，需要使用的JS接口列表
    //   openTagList: ["wx-open-launch-weapp"], // 可选，需要使用的开放标签列表，例如['wx-open-launch-weapp','wx-open-launch-app']
    // });
    // wx.ready(function () {
    //   console.log('wx success');
    //   // alert('wx success');
    //   //config信息验证后会执行ready方法，所有接口调用都必须在config接口获得结果之后，config是一个客户端的异步操作，所以如果需要在页面加载时就调用相关接口，则须把相关接口放在ready函数中调用来确保正确执行。对于用户触发时才调用的接口，则可以直接调用，不需要放在ready函数中
    // });
    // wx.error(function (res) {
    //   console.log("res", res);
    //   // config信息验证失败会执行error函数，如签名过期导致验证失败，具体错误信息可以打开config的debug模式查看，也可以在返回的res参数中查看，对于SPA可以在这里更新签名。
    // });


  },
  beforeDestroy() {
    window.onresize = null;
    console.log("index beforeDestroy");
  },
  beforeCreate() {},
  methods: {
    goyouzan() {
      var userAgent = navigator.userAgent.toLowerCase();
      if (userAgent.indexOf("micromessenger") !== -1) {
        this.ISwx = 1;
        // alert("当前页面在微信中打开");
        window.location.href = "https://j.youzan.com/8SkQaA";
      } else {
        this.ISwx = 0;
        // alert("当前页面不在微信中打开");
        window.location.href = "https://shop146579999.youzan.com";
      }
    },

    myswiper() {
      let that = this;

      setTimeout(() => {
        this.swiper = new Swiper(".sc", {
          duration: 1000,
          direction: "vertical",
          resistanceRatio: 0,
          on: {
            transitionEnd: function () {
              that.mselectIndex = this.activeIndex;
              if (that.mselectIndex > 0) {
                that.tb = false;
              } else {
                that.tb = true;
              }
            },
            init: function (swiper) {
              this.emit("transitionEnd");
              // console.log("slideTo:",that.id[1]);
            },
          },
        });
      }, 10);
    },

    myswiper0() {
      let that = this;

      setTimeout(() => {
        this.lswiper0 = new Swiper(".lsc0", {
          //子swiper
          direction: "vertical",
          nested: true,
          resistanceRatio: 0,
          slidesPerView: "auto",
          freeMode: true,

          // scrollbar: {
          //   el: ".swiper-scrollbar",
          // },
        });
      }, 10);
    },

    showdetall(kind, id) {
      this.$router.push(`/mdetall/${kind}/${id}/${this.lag}`);
    },

    godetall(link, title, kind, id) {
      // alert(title+link);
      if (link != "") {
        window.location.href = link;
      } else {
        if (title != "") {
          this.$router.push(`/mdetallm/${kind}/${id}/${this.lag}`);
        }
      }
    },

    fetch21(
      params = { show: 1, hot: 1, kind: "21", pagesize: 20, query: this.query }
    ) {
      let that = this;
      fetchCase(params).then((res) => {
        if (res.results.list) {
          that.clist21 = res.results.list;
        }
        console.log("clist21:", that.clist21);
        that.myswiper0();
      });
    },

    swiper1Prev() {
      this.swiper03.slidePrev(500);
    },
    swiper1Next() {
      this.swiper03.slideNext(500);
    },

    handleScroll(e) {
      // console.log("handleScroll");

      if (this.alive) {
        const winHeight =
          e.target.scrollTop || document.documentElement.scrollTop;
        // console.log("e.target.scrollTop:",e.target.scrollTop);
        global.setIndexscroll(winHeight);

        let group1h = $(".group_1").height();
        let group2h = $(".group_2").height();
        let group3h = $(".group_3").height();
        let group4h = $(".group_4").height();
        let group5h = $(".group_5").height();
        let group6h = $(".group_6").height();
        let group7h = $(".group_7").height();
        this.group1h = group1h;
        this.group2h = group2h;
        this.group3h = group3h;
        this.group4h = group4h;
        this.group5h = group5h;
        this.group6h = group6h;
        this.group7h = group7h;

        this.tb = true;

        if (
          this.winHeight >
          this.pageHeight +
            this.pageHeight +
            this.pageHeight +
            group2h +
            group4h +
            group6h +
            2000 +
            2000
        ) {
          console.log("down");
          this.tbanimate = "up";
          // $(".topbar").stop(true).animate({ top: "-140px" }, 100);
          $(".topbar").removeClass("downtopbar");
          $(".topbar").addClass("uptopbar");
        }

        if (winHeight > 120) {
          if (this.winHeight > winHeight && this.tbanimate != "down") {
            this.tbanimate = "down";
            // $(".topbar").stop(true).animate({ top: "0px" }, 100);
            $(".topbar").removeClass("uptopbar");
            $(".topbar").addClass("downtopbar");
          } else if (this.winHeight < winHeight && this.tbanimate != "up") {
            this.tbanimate = "up";
            // $(".topbar").stop(true).animate({ top: "-140px" }, 100);
            $(".topbar").removeClass("downtopbar");
            $(".topbar").addClass("uptopbar");
          }
        }

        this.winHeight = winHeight;
        if (winHeight > 400) {
          this.topbarOpacity = (winHeight - 400) / 200;
          if (this.topbarOpacity > 1) {
            this.topbarOpacity = 1;
          }
        } else {
          this.topbarOpacity = 0;
        }
      }
    },

    myswiper03() {
      setTimeout(() => {
        this.swiper03 = new Swiper(".sc03", {
          slidesPerView: 1,
          direction: "vertical",
          // slidesPerView: 2,
          // loop: true,
          // centeredSlides: true,
          spaceBetween: 0,
          // freeMode: true,
          // grabCursor: true,
          pagination: {
            el: ".sp03",
            clickable: true,
          },

          observer: true, //修改swiper自己或子元素时，自动初始化swiper
          observeParents: true, //修改swiper的父元素时，自动初始化swiper
          observeSlideChildren: true,
          on: {
            transitionEnd: function () {},
            init: function (swiper) {},
          },
        });
      }, 500);
    },

    windowopen(url) {
      if (url != "") {
        window.open(url);
      }
    },
    fetchb(params = {}) {
      let that = this;
      this.loading = true;

      fetchBase(params).then((res) => {
        // console.log("fetchBase:", res.results);
        that.loading = false;
        that.basedata = res.results;

        that.blinks[0].title = res.results.t0;
        that.blinks[1].title = res.results.t1;
        that.blinks[2].title = res.results.t2;
        that.blinks[3].title = res.results.t3;
        that.blinks[4].title = res.results.t4;
        that.blinks[5].title = res.results.t5;

        if (that.lag == "en") {
          that.blinks[0].title = res.results.ent0;
          that.blinks[1].title = res.results.ent1;
          that.blinks[2].title = res.results.ent2;
          that.blinks[3].title = res.results.ent3;
          that.blinks[4].title = res.results.ent4;
          that.blinks[5].title = res.results.ent5;

          that.basedata.t0 = res.results.ent0;
          that.basedata.t1 = res.results.ent1;
          that.basedata.t2 = res.results.ent2;
          that.basedata.t3 = res.results.ent3;
          that.basedata.t4 = res.results.ent4;
          that.basedata.t45 = res.results.ent45;
          that.basedata.t5 = res.results.ent5;
          that.basedata.t6 = res.results.ent6;
          that.basedata.t7 = res.results.ent7;
          that.basedata.t8 = res.results.ent8;
          that.basedata.t9 = res.results.ent9;
          that.basedata.t10 = res.results.ent10;

          that.basedata.tel = res.results.entel;
          that.basedata.adr = res.results.enadr;
          that.basedata.email = res.results.enemail;
          that.basedata.mtitle = res.results.enmtitle;
          that.basedata.mcontent = res.results.enmcontent;
          that.basedata.aititle = res.results.enaititle;
          that.basedata.aicontent = res.results.enaicontent;
          that.basedata.aicontent0 = res.results.enaicontent0;
          that.basedata.aicontent1 = res.results.enaicontent1;
          that.basedata.aicontent2 = res.results.enaicontent2;
          that.basedata.aicontent3 = res.results.enaicontent3;
          that.basedata.aicontent4 = res.results.enaicontent4;
        }
      });
    },

    selectclass2(id) {
      this.nopplistshow = 0;
      this.dataloading = 1;
      this.thisclass2 = id;
      // console.log(id);
      this.query = {
        class1: this.thisclass1,
        class2: [id],
      };
      this.data = [];
      setTimeout(() => {
        this.fetch();
      }, 500);
    },
    getClassList() {
      const that = this;
      fetchClass().then((res) => {
        that.classlist = res.results.list;

        // console.log("classlist:", that.classlist);

        that.class2list = this.classlist.filter((it) => {
          return this.thisclass1 == it.id;
        })[0]["list"];

        // console.log("class2list:", that.class2list);

        let mkinds = [];

        that.class2list.map((item) => {
          let cname = item.cname;
          if (that.lag == "en") {
            cname = item.encname;
          }
          mkinds.push({ name: cname, id: item.id });
        });

        that.mkinds = mkinds;

        console.log("mkinds:", this.mkinds);
      });
    },

    gowebsite(url) {
      window.open(url);
    },

    openBardiv() {
      this.barclass = "openthis";
    },
    closeBardiv() {
      this.barclass = "closethis";
    },
    handleResize() {
      this.resizepage();
    },

    resizeViewport() {
      console.log("resizeViewport m");
      let that = this;
      let bl = that.initHeight / window.innerWidth;
      if (bl > 1.2) {
        this.sectionindex = 0;
        this.m_group_2_height = this.pageHeight;
        this.w750 = 1;
        if (bl > 1.5) {
          $("meta[name=viewport]").attr(
            "content",
            "width=750,user-scalable=no"
          );
        } else {
          $("meta[name=viewport]").attr(
            "content",
            "width=1280,user-scalable=no"
          );
          // window.location.href = `#/indexm/${this.lag}`;
        }
      } else {
        $("meta[name=viewport]").attr("content", "width=1280,user-scalable=no");
        this.m_group_2_height = 0;
        this.w750 = 0;
        this.$router.replace(`/haowu/${this.id}/${this.lag}`);
        // window.location.reload();
      }
    },

    resizepage() {
      let that = this;
      this.pageWidth = window.innerWidth;
      let pageHeight = that.initHeight;

      this.pageHeight = pageHeight;

      $(".windowheight").css({ height: `${pageHeight}px` });
      setTimeout(() => {
        $(".windowheight").css({ height: `${this.pageHeight}px` });
      }, 1000);
      // // if (window.innerWidth > 1920) {
      // //   this.pageWidth = 1920;
      // // }
      // if (window.innerWidth <= 750) {
      //   this.sectionindex = 0;
      //   this.m_group_2_height = pageHeight;
      //   this.w750 = 1;
      //   window.location.href = `#/fengxiangm/${this.id}/${this.lag}`;
      // } else {
      //   this.m_group_2_height = 0;
      //   this.w750 = 0;
      // }

      this.resizeViewport();

      $("html").css({ fontSize: this.pageWidth / 192 });

      if (that.w750 == 0) {
      } else {
      }
    },
    changelag(lag) {
      if (lag == "en") {
        this.$router.push("index/en");
        setTimeout(() => {
          location.reload();
        }, 100);
      } else {
        this.$router.push("/index");
        setTimeout(() => {
          location.reload();
        }, 100);
      }
    },
    goDiv(classtext, url) {
      this.barclass = "closethis";
      if (url != "") {
        global.setIndexscroll(0);
        if (this.lag == "en") {
          url = `/${url}/en`;
        }
        this.$router.push(url);
        return false;
      }
      // this.$el
      //   .querySelector(`.${classtext}`)
      //   .scrollIntoView({ block: "start", behavior: "smooth" });

      global.setIndexDclass("");
    },

    goIndex() {
      this.$router.push(`/indexl`);
    },

    goSelect(index) {
      this.$router.push(`/sindex/${index}/${this.lag}`);
    },

    goMindex(id2) {
      let url = "";
      let id = this.id;
      if (id == 0) {
        url = "fengxiang";
      }
      if (id == 1) {
        url = "huoban";
      }
      if (id == 2) {
        url = "";
      }
      if (id == 3) {
        url = "show";
      }
      if (id == 4) {
        url = "club";
      }

      if (url != "") {
        this.$router.push(`/${url}/${id2}/${this.lag}`);
      }
    },

    blinkshandleHover(index) {
      this.bselectindex = index;
      // console.log(this.bselectindex);
    },
    sectionhandleHover(index) {
      if (this.w750 == 0) this.sectionindex = index;
    },
    blinkshandleLeave() {
      this.bselectindex = -1;
    },
    pphandleHover(index) {
      this.ppindex = index;
    },
    pphandleLeave() {},

    awardshandleHover(index) {
      this.awardsindex = index;
    },
    awardshandleLeave() {
      this.awardsindex = -1;
    },
  },
};
</script>


<style src="../assets/css/common.css" />
<style src="../assets/css/indexlm.css" />
<style src="../assets/css/indexl1280.css" />

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
